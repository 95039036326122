import axios from 'axios';
import { AuthTokenService } from '@/handler/authService';
// const HOST = 'b2b.dev.kiwiread.com'
const HOST = window.location.hostname
// Create an instance of axios
const api = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_URL, // Replace with your API base URL
    // timeout: 10000, // Set timeout to 10 seconds
    headers: {
        'Content-Type': 'application/json',
        'Client-Host': HOST
    },
});

// Create an instance of axios without token
const apiWithoutAuth = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_URL, // Replace with your API base URL
    // timeout: 10000, // Set timeout to 10 seconds
    headers: {
        'Content-Type': 'application/json',
        'Client-Host': HOST
    },
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = AuthTokenService.getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Define a request interceptor for the apiWithoutAuth instance
apiWithoutAuth.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
);

// Add a response interceptor for both instances
const responseInterceptor = (response) => response.data;

const responseErrorInterceptor = async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Mark that we've tried to refresh the token

        try {
            await updateToken();
            const token = AuthTokenService.getAccessToken();
            if (token) {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return api(originalRequest);
            }
        } catch (e) {
            AuthTokenService.clearTokens();
            AuthTokenService.redirectToLogin();
            return Promise.reject(e);
        }
    }
    return Promise.reject(error);
};

const updateToken = async () => {
    try {
        const response = await AuthTokenService.refreshToken();
        AuthTokenService.setTokens(response.data.access, response.data.refresh, localStorage.getItem('rememberMe'));
    } catch (error) {
        AuthTokenService.clearTokens();
        AuthTokenService.redirectToLogin();
        throw error; // Re-throw the error to ensure it propagates correctly
    }
};

api.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
apiWithoutAuth.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export { api, apiWithoutAuth };
