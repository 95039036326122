<template>
  <div class="question-render-container">
    <QText
      :text="question.header_text || payload.header_text"
      :headline="true"
      v-if="!customHeaderTextPlace"
    />
    <div v-if="slug === 'ra'">
      <Recorder
        @transcript:complete="(val) => syncAnswer('answer_audio_text', val)"
        @fluency:process="(val) => syncAnswer('answer_audio_fluency', val)"
        @fluency:pause="(val) => syncAnswer('answer_audio_fluency_pause', val)"
        @record:complete="(val) => recordComplete(val)"
        :autoRecord="true"
        :recordAfter="props.payload.preparation_time"
        :recordFor="
          props.payload.time_to_complete_the_answer
        "
        ref="recorder"
        :play-beep-before-record="
          props.payload.requires_beep_sound_before_audio
        "
        :showSkip="showSkip"
        :showInLabel="true"
      />
      <div class="mb-5"></div>
      <template v-if="$route.name === 'individualQuestion'">
        <QText :text="question.question_text" :dictionaryCondition="true" />
      </template>
      <template v-else>
        <QText :text="question.question_text" />
      </template>
    </div>
    <div v-else-if="slug === 'rs'">
      <Player
        :url="question.question_audio"
        :playAfter="props.payload.question_serve_time"
        @player:ended="playerEnded"
      />
      <Recorder
        @transcript:complete="(val) => syncAnswer('answer_audio_text', val)"
        @fluency:process="(val) => syncAnswer('answer_audio_fluency', val)"
        @fluency:pause="(val) => syncAnswer('answer_audio_fluency_pause', val)"
        @record:complete="(val) => recordComplete(val)"
        :autoRecord="true"
        :recordAfter="props.payload.preparation_time"
        :recordFor="
          props.payload.time_to_complete_the_answer
        "
        ref="recorder"
        :player-ended="playerStopped"
        :play-beep-before-record="
          props.payload.requires_beep_sound_before_audio
        "
      />
    </div>
    <div v-else-if="slug === 'di'">
      <v-row>
        <v-col cols="12" md="6">
          <QImage :source="question.question_image" />
        </v-col>
        <v-col cols="12" md="6">
          <Recorder
            @transcript:complete="(val) => syncAnswer('answer_audio_text', val)"
            @fluency:process="(val) => syncAnswer('answer_audio_fluency', val)"
            @fluency:pause="(val) => syncAnswer('answer_audio_fluency_pause', val)"
            @record:complete="(val) => recordComplete(val)"
            :autoRecord="true"
            :recordAfter="props.payload.preparation_time"
            :recordFor="
              props.payload.time_to_complete_the_answer
            "
            ref="recorder"
            :play-beep-before-record="
              props.payload.requires_beep_sound_before_audio
            "
            :showSkip="showSkip"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="['rl'].includes(slug)">
      <Player
        :url="question.question_audio"
        :playAfter="3"
        @player:ended="playerEnded"
      />
      <div class="mb-4"></div>
      <Recorder
        @transcript:complete="(val) => syncAnswer('answer_audio_text', val)"
        @fluency:process="(val) => syncAnswer('answer_audio_fluency', val)"
        @fluency:pause="(val) => syncAnswer('answer_audio_fluency_pause', val)"
        @record:complete="(val) => recordComplete(val)"
        :autoRecord="true"
        :recordAfter="10"
        :recordFor="
          props.payload.time_to_complete_the_answer
        "
        ref="recorder"
        :play-beep-before-record="
          props.payload.requires_beep_sound_before_audio
        "
        :player-ended="playerStopped"
      />
    </div>
    <div v-else-if="['asq'].includes(slug)">
      <Player
        :url="question.question_audio"
        :playAfter="props.payload.question_serve_time"
        @player:ended="playerEnded"
      />
      <div class="mb-4"></div>
      <Recorder
        @transcript:complete="(val) => syncAnswer('answer_audio_text', val)"
        @fluency:process="(val) => syncAnswer('answer_audio_fluency', val)"
        @fluency:pause="(val) => syncAnswer('answer_audio_fluency_pause', val)"
        @record:complete="(val) => recordComplete(val)"
        :autoRecord="true"
        :recordAfter="props.payload.preparation_time"
        :recordFor="
          props.payload.time_to_complete_the_answer
        "
        ref="recorder"
        :play-beep-before-record="
          props.payload.requires_beep_sound_before_audio
        "
        :player-ended="playerStopped"
      />
    </div>
    <div v-else-if="['we', 'swt'].includes(slug)">
      <template v-if="$route.name === 'individualQuestion'">
        <QText :text="question.question_text" :dictionaryCondition="true" />
      </template>
      <template v-else>
        <QText :text="question.question_text" />
      </template>
      <TextEditor @text:updated="(val) => syncAnswer('answer_text', val)" />
    </div>
    <div v-else-if="slug === 'fib-rw'">
      <template v-if="$route.name === 'individualQuestion'">
        <FillInTheBlank
          @selected="(val) => syncAnswer('answer_list', val)"
          :text="question.question_text"
          :options="question.options"
          :dictionaryCondition="true"
        />
      </template>
      <template v-else>
        <FillInTheBlank
          @selected="(val) => syncAnswer('answer_list', val)"
          :text="question.question_text"
          :options="question.options"
        />
      </template>
    </div>
    <div v-else-if="slug === 'mcm-r'" class="kiwi-fs-15 mcm-r">
      <v-row>
        <v-col cols="12" md="6">
          <template v-if="$route.name === 'individualQuestion'">
            <QText :text="question.question_text" :dictionaryCondition="true" />
          </template>
          <template v-else>
            <QText :text="question.question_text" />
          </template>
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="$route.name === 'individualQuestion'">
          <QText :text="question.sub_question_text" :headline="true" :dictionaryCondition="true" />
          </template>
          <template v-else>
          <QText :text="question.sub_question_text" :headline="true" />
          </template>
          <MultiChoice
            @selected="(val) => syncAnswer('answer_list', val)"
            :options="question.options"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="slug === 'ro'">
      <template v-if="$route.name === 'individualQuestion'">
        <Reorder
          @selected="(val) => syncAnswer('answer_list', val)"
          :options="question.options"
          :dictionaryCondition="true"
        />
      </template>
      <template v-else>
        <Reorder
          @selected="(val) => syncAnswer('answer_list', val)"
          :options="question.options"
        />

      </template>
    </div>
    <div v-else-if="slug === 'fib-r'">
      <template v-if="$route.name === 'individualQuestion'">
        <FillInTheBlank
          @selected="(val) => syncAnswer('answer_list', val)"
          :text="question.question_fill_in_the_blanks"
          :options="question.question_list"
          :isDrag="true"
          :dictionaryCondition="true"
        />
      </template>
      <template v-else>
      <FillInTheBlank
        @selected="(val) => syncAnswer('answer_list', val)"
        :text="question.question_fill_in_the_blanks"
        :options="question.question_list"
        :isDrag="true"
      />
      </template>
    </div>
    <div v-else-if="slug === 'mcq'">
      <v-row>
        <v-col cols="12" md="6">
          <template v-if="$route.name === 'individualQuestion'">
            <QText :text="question.question_text" :dictionaryCondition="true" />
          </template>
          <template v-else>
            <QText :text="question.question_text" />
          </template>
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="$route.name === 'individualQuestion'">
          <QText :text="question.sub_question_text" :dictionaryCondition="true" />
          </template>
          <template v-else>
            <QText :text="question.question_text" />
          </template>
          <SingleChoice
            @selected="(val) => syncAnswer('answer_id', val)"
            :options="question.options"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="['sst', 'wfd'].includes(slug)">
      <Player
        :url="question.question_audio"
        :playAfter="props.payload.question_serve_time"
        @player:ended="playerEnded"
      />
      <div class="mb-5"></div>
      <TextEditor @text:updated="(val) => syncAnswer('answer_text', val)" />
    </div>
    <div v-else-if="['hcs', 'mcs-l', 'mcm-l', 'smw'].includes(slug)">
      <v-row>
        <v-col cols="12" md="6">
          <Player
            :url="question.question_audio"
            :playAfter="props.payload.question_serve_time"
            @player:ended="playerEnded"
          />
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="$route.name === 'individualQuestion'">
            <QText :text="question.sub_question_text" :dictionaryCondition="true" />
          </template>
          <template v-else>
            <QText :text="question.sub_question_text" />
          </template>
          <MultiChoice
            @selected="(val) => syncAnswer('answer_list', val)"
            v-if="['mcm-l'].includes(slug)"
            :options="question.options"
          />
          <SingleChoice
            @selected="(val) => syncAnswer('answer_id', val)"
            v-else
            :options="question.options"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="slug === 'hiw'">
      <Player
        :url="question.question_audio"
        :playAfter="props.payload.question_serve_time"
        @player:ended="playerEnded"
      />
      <div class="mb-5"></div>
      <Highlight
        @selected="(val) => syncAnswer('answer_list', val)"
        :text="question.question_text"
      />
    </div>
    <div v-else-if="slug === 'fib-l'">
      <Player
        :url="question.question_audio"
        :playAfter="props.payload.question_serve_time"
        @player:ended="playerEnded"
        class="mb-5"
      />
      <div class="mb-5"></div>
      <template v-if="$route.name === 'individualQuestion'">
      <FillInTheBlank
        @selected="(val) => syncAnswer('answer_list', val)"
        :text="question.question_fill_in_the_blanks"
        :isTyping="true"
        :isDrag="false"
        :options="question.options"
        :dictionaryCondition="true"
      />
      </template>
      <template v-else>
      <FillInTheBlank
        @selected="(val) => syncAnswer('answer_list', val)"
        :text="question.question_fill_in_the_blanks"
        :isTyping="true"
        :isDrag="false"
        :options="question.options"
      />

      </template>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed } from "vue";
import Recorder from "@/components/MockTest/Recorder.vue";
import Player from "@/components/MockTest/Player.vue";
import QImage from "@/components/MockTest/QImage.vue";
import QText from "@/components/MockTest/QText.vue";
import TextEditor from "@/components/MockTest/TextEditor.vue";
import FillInTheBlank from "@/components/MockTest/FillInTheBlank.vue";
import MultiChoice from "@/components/MockTest/MultiChoice.vue";
import Reorder from "@/components/MockTest/Reorder.vue";
import SingleChoice from "@/components/MockTest/SingleChoice.vue";
import Highlight from "@/components/MockTest/Highlight.vue";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { mockPromise } from "@/utils/utils";
const route = useRoute();
const props = defineProps({
  moduleData: {
    type: Object,
    required: true,
  },
  payload: {
    type: Object,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  examType: {
    required: true,
    default: () => "mock",
  },
});
const emits = defineEmits(["record:completed"]);
const examStore = useAnswerInstanceStore();
const { questionId, answer } = storeToRefs(examStore);
const { setAnswer } = examStore;
const recorder = ref("");
const playerStopped = ref(false);
const question = computed(() => {
  return props.question;
});
const slug = computed(() => {
  return props.question.topic_slug;
});
const customHeaderTextPlace = computed(() => {
  return [].includes(slug.value)
});
const showSkip = computed(() => {
  return props.examType === "test";
});
function syncAnswer(key, value) {
  setAnswer({
    [key]: value,
  });
}
const recordComplete = (val) => {
  syncAnswer("answer_audio_file", val);
  if (val) {
    emits("record:completed");
  }
};
const stopRecorder = async () => {
  await (recorder.value ? recorder.value.stop() : mockPromise());
};
const playerEnded = () => {
  playerStopped.value = true;
};
defineExpose({
  stopRecorder,
  playerStopped,
});
</script>
<style lang="scss">
.question-render-container {
  .recorder-component {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
