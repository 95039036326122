import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useAnswerInstanceStore } from "./answerInstance-store";

export const useQuestionBasedTestStore = defineStore("questionBasedTestStore", () => {
  const fetching = ref(false)
  const restarting = ref(false)
  const questions = ref([])
  const testId = ref(null)
  const testDetail = ref(null)
  const answerInstanceStore = useAnswerInstanceStore();

  async function getTestDetail(id = null) {
    let url = `exam/scheduled-test/${id || testId.value}/`;
    const res = await api.get(url).then(res=>{
      testDetail.value = res.data
    }).catch(error =>{})
    console.log("test detail")
    return res;
  }
  async function start() {
    let url = `exam/scheduled-test/${testId.value}/start/`;
    const res = await api.post(url).then(res=>{}).catch(error =>{})
    return res;
  }
  async function restart(id) {
    restarting.value = true
    let url = `exam/scheduled-test/${id}/restart/`;
    const res = await api.post(url).then(res=>{
      restarting.value = false
      return res
    }).catch(error =>{
      restarting.value = false
      throw error
    })
    return res;
  }
  async function finish() {
    let url = `exam/scheduled-test/${testId.value}/finish/`;
    const res = await api.post(url).then(res=>res).catch(error =>{})
    return res;
  }
  async function getQuestionList() {
    let url = `exam/scheduled-test/${testId.value}/get-questions`;
    const res = await api.get(url).then(res=>{
      questions.value = res.data
    }).catch(error =>{
    })
    return res;
  }
  async function initTestInstance (id)
  {
    fetching.value = true
    testId.value = id
    await getTestDetail()
    if(!(testDetail.value && testDetail.value.is_attempted)) {
      await start()
    }
    await getQuestionList()
    fetching.value = false
  }
  function reset () {
    answerInstanceStore.resetAnswer()
    fetching.value = false
    restarting.value = false
    questions.value = []
    questions.value = []
    testDetail.value = null
    testId.value = null
  }
  return {fetching, testId, questions, initTestInstance, testDetail, finish, restart, restarting, reset, getTestDetail}
});
