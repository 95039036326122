import protected_routes from "@/router/protected_routes";

const guestroutes =[
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/Auth.vue')
  },
  {
    path: '/login',
    component: () => import('@/pages/Auth.vue'),
    name: 'login'
  },
  {
    path: '/forgot-password',
    component: () => import('@/components/Auth/ForgotPassword.vue')
  },
  {
    path: '/set-new-password',
    component: () => import('@/components/Auth/SetNewPassword.vue')
  },
  {
    path: '/set-password/:token',
    component: () => import('@/components/Auth/SetPassword.vue')
  },
  {
    path: '/terms-condition',
    component: () => import('@/pages/Website/Pages/terms.vue')
  },
  {
    path: '/reset/confirm',
    component: () => import('@/components/Auth/Confirmation.vue')
  },
  {
    path: '/register/confirm',
    name: 'sendVerification',
    component: () => import('@/components/Auth/RegisterConfirmation.vue')
  },
  {
    path: '/api/reset/confirm/:cat/:token',
    component: () => import('@/components/Auth/SetNewPassword.vue')
  },
  {
    path: '/api/verify-email/:verify/:token',
    name: 'verificationSuccess',
    component: () => import('@/components/Auth/RegisterConfirmation.vue')
  },

];
guestroutes.forEach(r => {
  const meta =(r.meta || {})
  r.meta = {
    ...{...meta, guest: true},
    requiresAuth: false
  }
})
export default guestroutes;

