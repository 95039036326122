<template>
    <Card :handleControl="welcomeControl" class="h-100 align-center kiwi-mockup-test">
        <v-row class="align-center">
            <v-col cols="12" sm="8" md="9" lg="10">
                <div class="d-flex flex-wrap align-center">
                    <span class="font-weight-bold kiwi-fs-20" style="flex: 1;">Mock-Tests</span>
                </div>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="2" class="text-sm-right">
                <router-link :to="{name:'mock-tests'}" class="kiwi-text-black text-decoration-none font-weight-semi-bold kiwi-fs-16">View All <v-icon class="mdi mdi-arrow-right" size="20px"></v-icon></router-link>
            </v-col>
        </v-row>
        <v-divider style="margin: 16px -16px 0" />

        <v-row class="mt-2">
            <v-col cols="12" sm="6" md="6" lg="4" v-for="(test, i) in mockTests" :key="i" v-show="i < 3">
                <TestGrid :type="'mock-test'" :test="test" />
            </v-col>
        </v-row>
    </Card>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import {ref , computed} from 'vue'
import { useDashboardStore } from "@/stores/dashboard-store";
import { storeToRefs } from "pinia";
import TestGrid from "@/components/Dashboard/TestGrid.vue";
const dashboardStore = useDashboardStore();
const {dashboardData} = storeToRefs(dashboardStore)
const  mockTests = computed(() => {
  return dashboardData.value && dashboardData.value.mock_tests !== undefined
    ? dashboardData.value.mock_tests
    : [];
});
const welcomeControl = ref({
    bg: 'white',
    textColor: 'black',
});

const progress = ref(Number)
progress.value = 0

const statusNotStarted = ref('Not Started')
const statusStarted = ref('Resume Test')
const statusCompleted = ref('Completed')
const start = ref('Start Test');

const mockTestList = ref([
    {
        id: 1,
        title: 'VIP - Mock Test 2',
    },
    {
        id: 2,
        title: 'VIP - Mock Test 3',
    },
    {
        id: 3,
        title: 'VIP - Mock Test 4',
    },
])
</script>

<style>
.kiwi-mockup-test .v-progress-circular__underlay {
    stroke: #F0F1F2 !important;
}
.v-progress-circular__overlay {
    stroke: #1CA972 !important;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);


}
</style>