<template>
    <Card :handleControl="welcomeControl" class="align-center">
        <v-row no-gutters class="">
            <v-col cols="12" md="7" lg="8" order="3" order-md="1">
                <article class="pa-4 pa-md-4">
                    <div class="title kiwi-fs-20 font-weight-bold">{{user && !user.is_email_verified ?welcomeContent.title : `Hello ${username}, Welcome to your PTE Exam preparation hub! Get ready to achieve your desired score on the PTE Exam and unlock exciting opportunities.` }}</div>
                    <div  class="title kiwi-fs-16 font-widht-medium mt-2">{{ user && !user.is_email_verified ? welcomeContent.text :  "Let’s help you to get started and get familiar with the dashboard, let's turn your aspirations into achievements!"}}</div>
                    <v-btn v-if="user && !user.is_email_verified" @click.native="emailVerify" variant="flat" class="kiwi-button kiwi-bg-black kiwi-text-white text-none font-weight-semi-bold kiwi-fs-14 mt-6" size="large" rounded="xl">Verify Now</v-btn>
                </article>
            </v-col>
            <v-spacer class="order-2 order-md-2"></v-spacer>
            <v-col cols="12" md="4" order="1" order-md="3">
                <figure class="h-100">
                    <img src="/assets/welcome_illustration.svg" class="kiwi-main-media h-100" alt="">
                </figure>
            </v-col>
        </v-row>
    </Card>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import {ref , computed} from 'vue'
import { useAuthStore } from "@/stores/auth/authStore";
import { storeToRefs } from "pinia";
import {useConfirmer} from '@/stores/confirmer';
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const confirmer = useConfirmer();
const  authStore = useAuthStore();
const {user} = storeToRefs(authStore);
const {verifyEmail} = authStore

const username = computed(() => {
  return user.value && user.value.user_full_name !== undefined 
    ? user.value.user_full_name !== null   ?  user.value.user_full_name : ''
    : '';
});
const emailVerify = async ()=>{
    try{
        await verifyEmail();
        confirmer.openSnackBar({type:'success' , msg:'Email Verification Link Sent Successfully'});    
    }catch(error){
        confirmer.openSnackBar({type:'error' , msg:error.response.data.data.data.error});
    }
}
const welcomeControl = ref({
    bg: 'white',
    textColor: 'black',
    mode: 'no-parent-padding',
    imgBg: '/assets/welcome_illustration.png',
    hasAction: true,
    contentWidth: '100',
});
const welcomeContent = computed(() => {
    return {
    title: `Welcome to ${institute.name} Dashboard! Get ready to excel with ${institute.name}!`,
    text: `Please verify your account to have full access to all available tests.`,
    }
})

</script>

<style>

</style>