<template>
  <div class="kiwi-dashboard-page">
    <v-row class="align-start">
      <v-col cols="12" md="8" lg="8" xl="9">
        <Welcome />
        <MockTest  class="mt-4 mt-md-4" />
        <QBTs  class="mt-4 mt-md-4" />
        <MockTests  class="mt-4 mt-md-4" />
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="3">
        <Refer  />
        <TargetScore class="mt-4 mt-md-4" />
        <Microphone  class="mt-4 mt-md-4" />
      </v-col>
    </v-row>
    <ScoreModal v-if="dashboardData && dashboardData.target_score === null" @update:dialog="updateDialog" :dialog="dashboardData && dashboardData.target_score === null && dialog"  />
  </div>
</template>

<script setup>
import Welcome from './Widget/Welcome.vue'
import MockTest from './Widget/MockTestFree.vue'
import QBTs from './Widget/QuestionBasedTests.vue'
import MockTests from './Widget/MockTests.vue'
import Refer from './Widget/ReferAFriend.vue'
import TargetScore from './Widget/TargetScore.vue'
import Microphone from './Widget/Microphone.vue'
import ScoreModal from '@/components/Dashboard/ScoreModal.vue'
import {ref} from 'vue'
import { onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth/authStore'
import {useDashboardStore} from '@/stores/dashboard-store'
import { storeToRefs } from "pinia";

const dashboardStore = useDashboardStore()
const dialog =  ref(true)
const store = useAuthStore();
const {dashboardData} = storeToRefs(dashboardStore)
const {getDashboardData} = dashboardStore
const {user} = storeToRefs(store)
const {getProfile} = store

const updateDialog = (newDialogValue) => {
    dialog.value = newDialogValue;
};

onMounted(()=>{
  if(!user.value){
    getProfile()
  }
  getDashboardData()
})
</script>
