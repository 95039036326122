<template>
  <footer class="kiwi-footer">
    <section class="kiwi-footer-top py-8">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <article class="d-flex flex-column">
              <img src="@/assets/logo.svg" style="width: 151px;" alt="">
              <p class="kiwi-fs-16 mt-6">An exceptional practice platform for PTE featuring curated mock tests with AI-powered scoring.</p>
            </article>
          </v-col>
          <v-col cols="12" md="1" class="d-none d-md-block"></v-col>
          <v-col cols="12" md="1" class="d-none d-md-block"></v-col>
          <v-col cols="12" sm="6" md="2">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">Company</h6>
              <v-list
                :lines="false"
                density="compact"
                :ripple="false"
                variant="flat"
                class="justify-md-center d-flex flex-column"
              >
                <v-list-item
                  v-for="(item, i) in company"
                  :key="i"
                  :value="item"
                  :to="item.link"
                  class="px-0"
                >
                  <v-list-item-title v-text="item.title" class="kiwi-fs-14"></v-list-item-title>
                </v-list-item>
              </v-list>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="overflow-hidden">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">SUPPORT</h6>
              <v-list
                :lines="false"
                density="compact"
                :ripple="false"
                variant="flat"
                class="justify-md-center d-flex flex-column"
                style=""
              >
                <v-list-item
                  v-for="(item, i) in support"
                  :key="i"
                  :value="item"
                  :to="item.link"
                  class="px-0"
                >
                  <v-list-item-title v-text="item.title" class="kiwi-fs-14"></v-list-item-title>
                </v-list-item>
              </v-list>
            </article>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <article class="d-flex flex-column">
              <h6 class="kiwi-fs-15 font-weight-semi-bold text-uppercase mb-3" style="">CONTACT US</h6>
              <div class="d-flex flex-column align-start justify-start py-2">
                <div class="kiwi-fs-15 mb-2">
                  <b class="font-weight-semi-bold">HQ :</b> <span>6143 Main St, Mt Jackson, VA 22842, USA</span>
                </div>
                <div class="kiwi-fs-15 mb-4">
                  <b class="font-weight-semi-bold">Nepal office:</b> Kupondole, Opposite to Hotel Himalaya 
                </div>
                <template v-for="(item, i) in contact" :key="i">
                  <template v-if="item.children">
                  <div
                    class="d-flex flex-row flex-wrap align-start text-decoration-none kiwi-text-black" 
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <div style="flex: 1;">
                      <template
                        v-for="(ch, j) in item.children"
                        :key="j"
                      >
                        <a
                          :href="ch.link" 
                          :class="j > 0 ? 'ml-2' : ''"
                          class="flex-grow-1 w-100 text-decoration-none kiwi-text-black" 
                        >
                          <span>{{ch.title}}</span>
                        </a>
                        <span v-if="j === 0">,</span>
                      </template>

                    </div>
                  </div>
                  </template>
                  <a 
                    :href="item.link" 
                    class="d-flex flex-row flex-wrap align-center text-decoration-none kiwi-text-black" 
                    :class="i > 0 ? 'mt-4' : ''"
                    v-else-if="!item.children"
                  >
                    <v-icon class="mdi mr-2 kiwi-text-primary" :class="item.icon"></v-icon>
                    <span>{{item.title}}</span>
                  </a>
                </template>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-divider></v-divider>
    <section class="kiwi-footer-bottom">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            Copyright &copy; 2024 by Susankya Global. All Rights Reserved.
          </v-col>
          <v-col cols="12" md="6" class="text-md-right">
            <div class="d-flex justify-md-end">
              <a class="kiwi-text-black text-decoration-none" target="_blank" href="https://www.facebook.com/profile.php?id=61560503900717&mibextid=ZbWKwL"><v-icon class="mdi mdi-facebook"></v-icon></a>
              <a class="kiwi-text-black text-decoration-none ml-4" target="_blank" href="https://www.instagram.com/kiwiread.ai?igsh=MTNydXNjODVwY2tzMA%3D%3D&utm_source=qr"><v-icon class="mdi mdi-instagram"></v-icon></a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </footer>
</template>

<script setup>
  import {ref} from 'vue'
  
  const company = ref([]);
  company.value = [
    {
      title: 'Products',
      link: '/product'
    },
    {
      title: 'Features',
      link: '/feature'
    },
    {
      title: 'Pricing',
      link: '/pricing'
    },
    {
      title: 'Blog',
      link: '/blog'
    },
  ]
  
  const support = ref([]);
  support.value = [
    {
      title: 'Privacy Policy',
      link: '/privacy'
    },
    {
      title: 'Terms of Service',
      link: '/terms-and-conditions'
    },
  ]

  const contact = ref([]);
  contact.value = [
    {
      title: 'Multiple Phone',
      icon: 'mdi-phone',
      link: 'tel:+18049561349',
      children: [
        {
          title: '+1-804-956-1349',
          link: 'tel:+18049561349'
        },
        {
          title: '9704552834',
          link: 'tel:9704552834'
        },
      ]
    },
    {
      title: 'info@kiwiread.com',
      icon: 'mdi-email',
      link: 'mailto:info@kiwiread.com'
    },
  ]
</script>

<style lang="scss" >
.v-list-item--nav .v-list-item-title.kiwi-fs-16 {
  font-size: 16px !important;
}

.kiwi-footer {
  .v-list-item::after, .v-list-item__overlay {
    display: none;
  }
}
</style>