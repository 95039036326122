<template>
    <WebLayout page="blog" :isGradientBgTop="false">
        <Banner mainClass="kiwi-blog-inner-banner py-12" :content="bannerContent" />
        <section class="kiwi-section pb-12 pb-md-16 pb-lg-20" v-if="showBlogs">
            <v-container>
                <!-- Feature Article -->
                <div v-if="pagination.page == 1">
                    <BlogCard class="mb-8 mb-md-12" mode="two-column" :blogContent="featureContent"/>
                    <router-view></router-view>
                    <v-divider class="mb-8 mb-md-12"></v-divider>
                </div>
                <!-- Feature Article end -->
                <v-sheet variant="flat" color="transparent">
                    <h2 class="kiwi-fs-24 font-weight-semi-bold mb-8">All Blog Post</h2>
                    <v-row>
                        <v-col v-for="(blog, i) in blogList" cols="12" md="6" lg="4">
                            <BlogCard mode="single" :blogContent="blog" />
                        </v-col>
                    </v-row>
                    <div class="mt-8 mt-md-16 d-flex justify-center">
                        <!-- <v-pagination :length="5"></v-pagination> -->
                        <v-pagination
                            v-model="pagination.page"
                            :length="pageCount"
                            :total-visible="totalVisible"
                            variant="text"
                            density="compact"
                            @update:modelValue="getBlogs"
                        ></v-pagination>
                    </div>
                </v-sheet>    
            </v-container>
        </section>
        
        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout.vue'
import Banner from '../Addon/InnerBanner.vue'
import BlogCard from '../Addon/BlogCard.vue'
import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
import {onMounted, ref} from 'vue'
import { apiWithoutAuth } from '@/plugins/axios'
import { reactive } from 'vue'
import { computed } from 'vue'

const bannerContent = ref({})
bannerContent.value = {
    title: 'KiwiRead Blog',
    text: 'Explore PTE Tips, Inspiration and Expert Advice'
}

const featureContent = ref({})
// const featureContent = ref({})
// featureContent.value = {
//     id: 1,
//     featureImage: {
//         src: '/assets/blog-image-one.png',
//         rounded: 8,
//     }, 
//     articleContent: {
//         id: 1,
//         author: {
//             name: 'Sheetal Rai',
//             date: '18th July'
//         },
//         title: 'Preparing for the PTE Exam: A Guide',
//         titleSize: 'kiwi-fs-40',
//         text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//         tags: ['PTE', 'Exam', 'Preparation'],
//         hasAction: true,
//         action: {
//             label: 'Read More',
//             rounded: 'xl',
//             size: 'x-large',
//             variant: 'outlined',
//             color: 'kiwi-border-primary kiwi-text-primary text-capitalize text-body-1',
//             height: '40px',
//             width: '126px'
//         }

//     }
// }

const blogContents = ref([])
const showBlogs = ref(false)
const total = ref(0);
const totalVisible = ref(10);
const defaultPageSize = 6
const pagination = reactive({
        pageSize:defaultPageSize,
        page:1,
    })  
const pageCount = computed(() => {
  return Math.ceil(total.value / pagination.pageSize);
}); 
const blogList = computed(() => {
    return blogContents.value.filter(blog => blog.id != featureContent.value.id)
})
const getBlogs = async () => {
    if(pagination.page == 1){
        pagination.pageSize = defaultPageSize + 1
    }else{
        pagination.pageSize = defaultPageSize
    }
    const url = ref(`/common/blogs?page=${pagination.page}&limit=${pagination.pageSize}`)
    if(pagination.page == 2){
        url.value = `/common/blogs?page=${pagination.page}&limit=${pagination.pageSize}&offset=${pagination.pageSize+1}`
    }
    const res = await apiWithoutAuth.get(url.value)
    showBlogs.value = true
    total.value = res.data.count
    blogContents.value = res.data.results
    return res
}
onMounted(async () => {
    try {
        await getBlogs()
        featureContent.value = blogContents.value.splice(0, 1)[0]
    } catch (error) {
        console.log(error)
    }
})

// blogContents.value = [
//     {
//         id: 2,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id: 2,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
//     {
//         id: 3,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id: 3,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
//     {
//         id:4,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id: 4,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
//     {
//         id:5,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id:5,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
//     {
//         id:6,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id:6,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
//     {
//         id:7,
//         featureImage: {
//             src: '/assets/blog-image-one.png',
//             rounded: 8,
//         }, 
//         articleContent: {
//             id:7,
//             author: {
//                 name: 'Sheetal Rai',
//                 date: '18th July'
//             },
//             title: 'Preparing for the PTE Exam: A Guide',
//             titleSize: 'kiwi-fs-20',
//             text: 'The PT exam is a crucial step for aspiring physical therapists, and it is essential to prepare thoroughly to pass the exam. This blog post will provide a comprehensive gu...',
//             tags: ['PTE', 'Exam', 'Preparation'],
//         }
//     },
// ]

const subscribeContent = ref({})
subscribeContent.value = {
    imgSrc: '/assets/subscribe-img.png',
    setMediaCol: 6,
    setArticleCol: 4,
    category: null,
    title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your PTE journey!',
    text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
    hasAction: true,
    showMask: true,
    action: {
        label: 'Start Free Trial',
        color: 'kiwi-bg-primary',
        to: {name: 'register'}
    }
}
</script>