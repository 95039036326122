<template>
    <WebLayout page="pricing" :isGradientBgTop="true">
        <Banner mainClass="kiwi-pricing-inner-banner py-12" :content="bannerContent" />

        <section class="kiwi-section kiwi-pricing-list py-12 pt-md-0 pb-md-16 pt-lg-0 pb-lg-20">
            <v-container>
                <v-row class="align-start">
                    <v-col cols="12" md="4" v-for="(content, i) in packages" :key="i">
                        <PricingCard :content="content" />
                    </v-col>
                </v-row>
            </v-container>
        </section>
        
        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout'
import Banner from '../Addon/InnerBanner.vue'
import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
import PricingCard from '../Addon/PricingCard.vue'
import {onMounted, ref} from 'vue'
import { usePackageStore } from "@/stores/package-store";
import { storeToRefs } from 'pinia'

const packageList = usePackageStore()
const { packages } = storeToRefs(packageList)
const { getPackageList } = packageList

const bannerContent = ref({})
bannerContent.value = {
    title: 'Pricing Plans',
    text: 'Our tiered pricing plans provide the perfect balance of features and affordability.'
}

// const pricingLists = ref([])
// pricingLists.value = [
//     {
//         id: 1,
//         active: false,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'free plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
//     {
//         id: 2,
//         active: true,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'pro plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check',
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 4,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
//     {
//         id: 3,
//         active: false,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'premium plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
// ]

const subscribeContent = ref({})
subscribeContent.value = {
    imgSrc: '/assets/subscribe-img.png',
    setMediaCol: 6,
    setArticleCol: 4,
    category: null,
    title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your PTE journey!',
    text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
    hasAction: true,
    showMask: true,
    action: {
        label: 'Start Free Trial',
        color: 'kiwi-bg-primary',
        to: {name: 'register'}
    }
}

onMounted(() => {
    getPackageList()
})
</script>

<style>

</style>