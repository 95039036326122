import { ref } from 'vue';
import hark from 'hark';
export function useTranscribeAudio() {
  const transcript = ref('');
  const fluency = ref(null);
  const averagePause = ref(0);
  const recognition = ref(null);
  const recognizing = ref(false);
  const speechEventInstance = ref(null)

  const startTranscription = () => {
    if (!('webkitSpeechRecognition' in window)) {
      console.log('Your browser does not support speech recognition.');
      return;
    }

    recognition.value = new webkitSpeechRecognition();
    recognition.value.lang = 'en-US';
    recognition.value.interimResults = true;
    recognition.value.continuous = true;
    recognition.value.maxAlternatives = 1;
    let transcriptStr = ''
    transcript.value = null
    fluency.value = null
    // averagePause.value = null
    let myfluency = 5.0; // Initial fluency score set to 5
    let t0 = 0;
    let t1 = 0;
    let pauseSum = 0;
    let pauseNum = 0;
    recognition.value.onresult = (event) => {
      const interimTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('');
        transcript.value = interimTranscript
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (t0 != 0) {
              t1 = performance.now();
              let pauseinstring = t1 - t0;
              pauseSum +=  pauseinstring
              pauseNum += 1
              if (pauseinstring >= 450 && pauseinstring <= 550) {
                  myfluency = myfluency - 0.1;
              }
              else if (pauseinstring >= 560 && pauseinstring <= 590) {
                  myfluency = myfluency - 0.2;
              }
              else if (pauseinstring >= 600 && pauseinstring <= 650) {
                  myfluency = myfluency - 0.3;
              }
              else if (pauseinstring >= 660 && pauseinstring <= 690) {
                  myfluency = myfluency - 0.4;
              }
              else if (pauseinstring >= 700 && pauseinstring <= 750) {
                  myfluency = myfluency - 0.5;
              }
              else if (pauseinstring >= 800) {
                  myfluency = myfluency - 1;
              }
              if (myfluency <= 0) {
                  myfluency = 0;
              }
          } else {
              t0 = performance.now();
              t1 = t0;
          }
          t0 = performance.now();
          fluency.value = myfluency ? (myfluency * 90) / 5 : 0
          // averagePause.value = pauseNum && pauseSum ? pauseSum / pauseNum : 0
      }
    };

    recognition.value.onend = () => {
      recognizing.value = false;
    };

    recognition.value.onerror = (event) => {
      console.error('Speech Recognition Error:', event.error);
    };

    recognition.value.start();
    recognizing.value = true;
  };

  const stopTranscription = () => {
    if (recognition.value && recognizing.value) {
      recognition.value.stop();
      recognizing.value = false;
    }
  };
  const startPauseDetection = async () => {
    averagePause.value = null
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const options = {
      interval: 10
    };
    const speechEvents = hark(stream, options);
    speechEventInstance.value = speechEvents
    let lastStoppedSpeakingTime = 0;
    let pauses = [];
    let totalPauseDuration = 0;
    speechEvents.on('speaking', () => {
      const now = Date.now();
      if (lastStoppedSpeakingTime) {
        const pauseDuration = now - lastStoppedSpeakingTime;
        pauses.push(pauseDuration);
        totalPauseDuration += pauseDuration;
        averagePause.value = totalPauseDuration / pauses.length
      }
    });
    speechEvents.on('stopped_speaking', () => {
      lastStoppedSpeakingTime = Date.now();
    });
  }
  const stopPauseDetection = async () => {
      if(speechEventInstance.value) {
        speechEventInstance.value.stop();
      }
      speechEventInstance.value = null
  }
  return {
    transcript,
    fluency,
    recognizing,
    startTranscription,
    stopTranscription,
    startPauseDetection,
    stopPauseDetection,
    averagePause
  };
}
