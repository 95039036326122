<template>
    <WebLayout page="product" :isGradientBgTop="true">
        <HeroBanner mainClass="product-banner overflow-hidden" :hasWaveBg="true" class="pt-12 pt-md-16 pt-lg-20" :content="bannerContent" />
        <section class="product-list-section" style="border-top: 1px solid; border-bottom: 1px solid; border-color: var(--color-light-grey-3);">
            <v-row class="ma-2">
                <v-col cols="12" md="4" lg="" class="pa-2">
                    <div class="d-flex flex-column h-100 kiwi-radius-8 w-100 product-box pte active border kiwi-border-light-grey-3">
                        <div class="d-flex align-center justify-center header w-100 position-relative overflow-hidden" style="height: 274px;">
                            <img src="/assets/product-active-highlight.png" class="position-absolute z-1" style="top: 0; left: 0; width: 100%;">
                            <div class="d-flex align-center logo position-relative z-3">
                                <img src="/assets/PTE.png" class="mr-2" alt="PTE Logo" style="height: 44px;"> <span class="kiwi-fs-24 kiwi-text-black-3">PTE</span>
                            </div>
                        </div>
                        <div class="action d-flex align-center justify-center" style="padding: 16px; border-top: 1px solid var(--color-light-grey-3);">
                            <v-btn 
                                class="kiwi-btn kiwi-text-white text-body-1 text-capitalize" 
                                rounded="xl" 
                                min-width="200px" 
                                height="50px"
                                :ripple="false"
                                variant="flat"
                                to="/register"
                                style="background: linear-gradient(295.68deg, #1CA972 -8.52%, #7DEE61 124.99%);"
                            >Start Free Trial</v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="" class="pa-2">
                    <div class="d-flex flex-column h-100 kiwi-radius-8 w-100 product-box sat border kiwi-border-light-grey-3">
                        <div class="d-flex align-center justify-center header w-100" style="height: 274px;">
                            <div class="d-flex align-center logo">
                                <img src="/assets/SAT.png" class="" alt="SAT Logo" style="height: 32px;">
                            </div>
                        </div>
                        <div class="action d-flex align-center justify-center" style="padding: 16px; border-top: 1px solid var(--color-light-grey-3);">
                            <v-btn 
                                class="kiwi-btn coming-soon kiwi-text-white text-body-1 text-capitalize" 
                                rounded="xl" 
                                min-width="200px" 
                                height="50px"
                                :ripple="false"
                                variant="flat"
                                disabled
                                style="background: linear-gradient(295.68deg, rgba(28, 169, 114, 0.2) -8.52%, rgba(125, 238, 97, 0.2) 124.99%); opacity: 1;"
                            >Coming Soon</v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="" class="pa-2">
                    <div class="d-flex flex-column h-100 kiwi-radius-8 w-100 product-box pte border kiwi-border-light-grey-3">
                        <div class="d-flex align-center justify-center header w-100" style="height: 274px;">
                            <div class="d-flex align-center logo">
                                <img src="/assets/PTE.png" class="mr-2" alt="PTE Logo" style="height: 44px;"> <span class="kiwi-fs-24 kiwi-text-black-3">PTE | Core</span>
                            </div>
                        </div>
                        <div class="action d-flex align-center justify-center" style="padding: 16px; border-top: 1px solid var(--color-light-grey-3);">
                            <v-btn 
                                class="kiwi-btn coming-soon kiwi-text-white text-body-1 text-capitalize" 
                                rounded="xl" 
                                min-width="200px" 
                                height="50px"
                                :ripple="false"
                                variant="flat"
                                disabled
                                style="background: linear-gradient(295.68deg, rgba(28, 169, 114, 0.2) -8.52%, rgba(125, 238, 97, 0.2) 124.99%); opacity: 1;"
                            >Coming Soon</v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="" class="pa-2">
                    <div class="d-flex flex-column h-100 kiwi-radius-8 w-100 product-box ielts border kiwi-border-light-grey-3">
                        <div class="d-flex align-center justify-center header w-100" style="height: 274px;">
                            <div class="d-flex align-center logo">
                                <img src="/assets/IELTS.png" class="" alt="IELTS Logo" style="height: 32px;">
                            </div>
                        </div>
                        <div class="action d-flex align-center justify-center" style="padding: 16px; border-top: 1px solid var(--color-light-grey-3);">
                            <v-btn 
                                class="kiwi-btn coming-soon kiwi-text-white text-body-1 text-capitalize" 
                                rounded="xl" 
                                min-width="200px" 
                                height="50px"
                                :ripple="false"
                                variant="flat"
                                disabled
                                style="background: linear-gradient(295.68deg, rgba(28, 169, 114, 0.2) -8.52%, rgba(125, 238, 97, 0.2) 124.99%); opacity: 1;"
                            >Coming Soon</v-btn>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="" class="pa-2">
                    <div class="d-flex flex-column h-100 kiwi-radius-8 w-100 product-box duolingo border kiwi-border-light-grey-3">
                        <div class="d-flex align-center justify-center header w-100" style="height: 274px;">
                            <div class="d-flex align-center logo">
                                <img src="/assets/Duolingo.png" class="" alt="Duolingo Logo" style="height: 93px;">
                            </div>
                        </div>
                        <div class="action d-flex align-center justify-center" style="padding: 16px; border-top: 1px solid var(--color-light-grey-3);">
                            <v-btn 
                                class="kiwi-btn coming-soon kiwi-text-white text-body-1 text-capitalize" 
                                rounded="xl" 
                                min-width="200px" 
                                height="50px"
                                :ripple="false"
                                variant="flat"
                                disabled
                                style="background: linear-gradient(295.68deg, rgba(28, 169, 114, 0.2) -8.52%, rgba(125, 238, 97, 0.2) 124.99%); opacity: 1;"
                            >Coming Soon</v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </section>
        <TwoColSectionArticle 
            mainClass="kiwi-pte-mock-test" 
            bgColor="kiwi-bg-white" 
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'first', article: 'last' }" 
            :content="aboutProduct"
        />
        
        <!-- How Does It Work Section start -->
        <HeroBanner 
            mainClass="kiwi-how-does-it-work" 
            class="py-12 py-md-16 py-lg-20 kiwi-bg-light-green" 
            :content="serviceContent" 
            :cardControl="{ shadow: false, bg: 'transparent', loopCardBg: 'white', md: 3, variant: 'outlined'  }" 
        />
        <!-- How Does It Work Section end -->
        
        <!-- Speaking Section start -->
        <HeroBanner 
            mainClass="kiwi-speaking" 
            class="py-12 py-md-16 py-lg-20" 
            :content="speakingContent" 
            :cardControl="{ shadow: false, bg: 'transparent', md: 4, gridAlign: 'center', nomedia: true, variant: 'outlined', cardTextAlign: 'left'  }" 
        />
        <!-- Speaking Section end -->

        <TwoColSectionArticle 
            mainClass="kiwi-pte-mock-test" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="writingContent"
        />

        <TwoColSectionArticle 
            mainClass="kiwi-pte-mock-test"
            class="py-12 py-md-16 py-lg-20" 
            :orderControl="{ media: 'first', article: 'last' }" 
            :content="readingContent"
        />
        
        <!-- Listening Section start -->
        <HeroBanner 
            mainClass="kiwi-speaking" 
            class="py-12 py-md-16 py-lg-20 kiwi-bg-light-green" 
            :content="listeningContent" 
            :listControl="{ shadow: false, bg: 'transparent', md: 6, nomedia: true, variant: 'outlined',  }" 
        />
        <!-- Listening Section end -->
        
        <!-- Faq Section start -->
        <Accordion 
            mainClass="kiwi-faq" 
            class="py-12 py-md-16 py-lg-20" 
            :content="faqContentData" 
        />
            <!-- :accordionControl="{ shadow: false, bg: 'transparent', md: 6, nomedia: true, variant: 'outlined',  }"  -->
        <!-- Faq Section end -->

        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
    import WebLayout from '../WebLayout.vue'
    import HeroBanner from '../Addon/HeroBanner.vue'
    import Accordion from '../Addon/Accordion.vue'
    import InfoBox from '../Addon/InfoCard.vue'
    import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
    import Masonary from '../Addon/MasonaryLayout.vue'
    import Article from '../Addon/ArticleFormatCard1.vue'
    import {useConfigStore} from '@/stores/configStore'
    import {onMounted, ref} from 'vue'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

    const bannerContent = ref({})
    const config = useConfigStore()
    const { faqContent } = storeToRefs(config)
    const { getFaqContent } = config

    bannerContent.value = {
        width: '100%',
        parentHeight: '350px',
        title: `Achieve English </br> Proficiency with KiwiRead`,
        text: `Our tiered pricing plans provide the perfect balance of features and affordability.`,
        valign: 'center',
    }

    const aboutProduct = ref({})
    aboutProduct.value = {
        imgSrc: '/assets/product_about-img.png',
        category: {
            name: 'About Us',
            color: 'kiwi-text-primary',
            transform: 'capitalize'
        },
        title: 'All about PTE Exam',
        text: 'The Pearson Test of English (PTE) is a computer-based English language proficiency exam widely accepted by academic institutions and governments globally. Unlike traditional paper-based tests, PTE delivers results within 48 hours and offers flexible scheduling. Utilizing advanced AI technology, this test evaluates your speaking, writing, reading, and listening skills through tasks, making it ideal for students aiming for international education, professionals seeking international education or individuals requiring proof of English for migration.',
        hasAction: false,
    }

    const serviceContent = ref({})
    serviceContent.value = {
        category: {},
        width: '700px',
        title: 'How does it work?',
        titleSize: 'text-h3',
        text: 'Begin your learning journey today and achieve your goals with these simple steps. Here’s how to get started and excel with our platform.',
        textSize: '16',
        categoryLists: [
            {
                icon: '/assets/register-login.svg',
                title: 'Register / Login',
                text: `Get started today! Register for a free account or log in if you're already a member.`
            },
            {
                icon: '/assets/claim-free-test.svg',
                title: 'Claim Free Test',
                text: `Don't wait! Claim your free practice test and jumpstart your learning journey.`
            },
            {
                icon: '/assets/explore-new-tests.svg',
                title: 'Explore New Tests',
                text: `Discover new challenges! Immerse yourself in the latest tests available on our platform.`
            },
            {
                icon: '/assets/aquire-targeted-score.svg',
                title: 'Acquire Targeted Score',
                text: `Unlock your full potential. Reach your target score with our learning platform.`
            },
        ]
    }

    const speakingContent = ref({})
    speakingContent.value = {
        category: {},
        width: '700px',
        title: 'Speaking',
        titleSize: 'text-h3',
        text: `This section evaluates the test-taker's ability to communicate effectively in English including fluency and pronunciation,This segment includes five diverse question types designed to test various aspects of English language skills.`,
        textSize: '16',
        categoryLists: [
            {
                title: 'Read Aloud',
                text: `Ensure clear pronunciation, maintain a natural pace, and use appropriate intonation.`
            },
            {
                title: 'Repeat Sentence',
                text: `Listen well, natural speech & clarity are essential skills for effective communication.`
            },
            {
                title: 'Describe Image',
                text: `By carefully observing and using descriptive vocabulary can help you better understand.`
            },
            {
                title: 'Re-tell Lecture',
                text: `Summarize key points, use transitions to increase your chances of success.`
            },
            {
                title: 'Answer Short Question',
                text: `Take time to carefully craft your writing using concise, complete sentences, proper language.`
            },
        ]
    }

    const writingContent = ref({})
    writingContent.value = {
        imgSrc: '/assets/Blogging-cuate.png',
        category: {},
        title: 'Writing',
        text: `This section tests the test-taker's ability to express themselves clearly, using the right words and grammar.`,
        hasAction: false,
        hasList: true,
        articleLists: [
            {
                title: 'Summarize Written Text',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Capture key points, concise, relevant vocabulary.',
                }
            },
            {
                title: 'Write an Essay',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Strong thesis, clear structure, support arguments with examples.',
                }
            },
        ]
    }

    const readingContent = ref({})
    readingContent.value = {
        imgSrc: '/assets/Online-test-amico.png',
        category: {},
        title: 'Reading',
        text: `This section aims to measure a candidate's proficiency in reading and speaking clearly and fluently in academic contexts. It evaluates the test-taker's ability to understand written English text.`,
        hasAction: false,
        hasList: true,
        articleLists: [
            {
                title: 'Reading & Writing : Fill in the blanks',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Choose the word that fits the grammar and makes sense in context.',
                }
            },
            {
                title: `Reading: Multiple Choice, Choose Multiple Answer (Single)`,
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Skim keywords, choose multiple with support (avoid extremes).',
                }
            },
            {
                title: 'Re-order Paragraphs',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Transitions! Main ideas. Logical flow (check 1st & last sentences).',
                }
            },
            {
                title: 'Reading: Fill in the Blanks',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Context key. Choose fit (grammar & sentence).',
                }
            },
            {
                title: 'Reading: Multiple Choice, Choose Single Answer (Single)',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: ' Skim keywords. Eliminate & choose only one supported',
                }
            },
        ]
    }

    
    const listeningContent = ref({})
    listeningContent.value = {
        category: {},
        width: '700px',
        title: 'Listening',
        titleSize: 'text-h3',
        text: `This section tests a test taker's ability to listen and understand spoken English. It aims to measure a candidate's proficiency in listening and comprehending English spoken in various contexts and accents.`,
        textSize: '16',
        articleLists: [
            {
                title: 'Read Summarize Spoken Text',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Focus on main ideas & supporting details, take notes (optional)  to effectively capture the key points of the spoken content',
                }
            },
            {
                title: 'Multiple Choice, Single Answer',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Focus on the question, skim keywords and choose that best matches what you hear.',
                }
            },
            {
                title: 'Multiple Choice, Multiple Answers',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Eliminate unlikely options, choose answers with matching details to increase the chances of answering correctly.',
                }
            },
            {
                title: 'Select Missing Word',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: 'Listen for synonyms or rephrased versions of the missing word and make sure to carefully consider the context.',
                }
            },
            {
                title: 'Fill in the Blanks',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: ' Read options beforehand, listen for keywords and choose the word that fits the context.',
                }
            },
            {
                title: 'Highlight Incorrect Words',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: ' Pay attention to emphasis and detail to judge wrong information or words spoken.',
                }
            },
            {
                title: 'Highlight Correct Summary',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: ' Read summaries, choose the one with accurate main ideas and supporting details.',
                }
            },
            {
                title: 'Write from Dictation',
                props: {
                    prependIcon: 'mdi-arrow-right',
                    subtitle: ' Focus on clear sounds and short phrases, write what you hear accurately.',
                }
            },
        ]
    }

    const faqList = computed(() => {
        if(faqContent.value.length > 0){

            return faqContent.value.map(faq => ({
                title: faq.question,
                text: faq.answer
            }))
        }else{
            return []
        }
    })
    const faqContentData = ref({})
    faqContentData.value = {
        category: {},
        width: '700px',
        title: 'FAQ',
        titleSize: 'text-h3',
        text: null,
        textSize: '16',
        hasAction: false,
        accordionLists: faqList
    }

    const testimonial = ref({})
    testimonial.value = {
        info: {
            category: {
                name: 'Testimonial',
                color: 'kiwi-text-primary',
                transform: 'capitalize',
            },
            title: 'Read from our satisfied <span class="kiwi-text-primary">Clients</span>',
            text: 'Our clients are at the core of everything we do. Their satisfaction is not just a ',
        },
        items: [
            {
                id: 1,
                client: {
                    imgSrc: '/assets/sheelu-sharma.png',
                    name: 'Sheelu Sharma',
                    cite: 'Malaysia'
                },
                text: '“Take unlimited practice tests and receive instant feedback to identify areas for improvement. Take unlimited practice tests and receive instant feedback to identify areas for improvement.”'
            },
            {
                id: 2,
                client: {
                    imgSrc: '/assets/samir-shrestha.png',
                    name: 'Samir Shrestha',
                    cite: 'Malaysia'
                },
                text: '“Take unlimited practice tests and receive instant feedback to identify areas for improvement.”'
            },
            {
                id: 3,
                client: {
                    imgSrc: '/assets/sheelu-sharma.png',
                    name: 'Sheelu Sharma',
                    cite: 'Malaysia'
                },
                text: '“Take unlimited practice tests and receive instant feedback to identify areas for improvement. Take unlimited practice tests and receive instant feedback to identify areas for improvement.”'
            },
            {
                id: 4,
                client: {
                    imgSrc: '/assets/avash-chettri.png',
                    name: 'Avash Chettri',
                    cite: 'Malaysia'
                },
                text: '“Take unlimited practice tests and receive instant feedback to identify areas for improvement.”'
            },
        ]
    }

    const subscribeContent = ref({})
    subscribeContent.value = {
        imgSrc: '/assets/subscribe-img.png',
        setMediaCol: 6,
        setArticleCol: 4,
        category: null,
        title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your PTE journey!',
        text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
        hasAction: true,
        showMask: true,
        action: {
            label: 'Start Free Trial',
            color: 'kiwi-bg-primary',
            to: {name: 'register'}
        }
    }

    onMounted(async () => {
        await getFaqContent()
    })
</script>

<style lang="scss">
.coming-soon {
    
    &.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay, 
    &.v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
        opacity: 0;
    }
    .v-btn__content {
        background: linear-gradient(295.68deg, #1CA972 -8.52%, #7DEE61 124.99%);
        -webkit-background-clip: text;
        color: transparent;
    }
}

.product-box {
    &.active {
        overflow: hidden;
        border: none;
        padding: 2px;
        background: linear-gradient(348.23deg, rgba(35, 147, 35, 0.3) 21.04%, #1CA972 76.52%);
    }
    
    &.pte .header {
        background: linear-gradient(180deg, #EEF8FF 0%, #FFFFFF 100%);
    }
    
    &.sat .header {
        background: linear-gradient(180deg, #DDF7FF 0%, #FFFFFF 100%);
    }
    
    &.ielts .header {
        background: linear-gradient(180deg, #FFF1F4 0%, #FFFFFF 100%);
    }
    
    &.duolingo .header {
        background: linear-gradient(180deg, #FAFFF1 0%, #FFFFFF 100%);
    }

    &.active .header {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #E8FFEC 0%, #F0FFED 100%);
            opacity: 0.8;
        }
    }

    .action {
        background: var(--color-white);
    }

    .coming-soon {
        font-weight: 600;
    }

    .v-btn {
        font-size: 16px !important;
    }

}

</style>