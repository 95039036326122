// useMicrophonePermission.js
import { ref } from "vue";
import { usePermission } from "@vueuse/core";
import { context, Player, Recorder, UserMedia } from "tone";
import  beep  from "@/assets/beep.mp3";

export function useMicrophone() {
  const microphoneAccess = ref(usePermission("microphone"));
  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      microphoneAccess.value = "granted";
    } catch (error) {
      microphoneAccess.value = "denied";
    }
  };

  const micSupported = UserMedia.supported;
  const playbackSupported = context.state === "running";

  const recording = ref(false);
  const initialized = ref(false);
  const playing = ref(false);
  const file = ref("");
  let mic, recorder, player;

  const toggleRecording = async () => {
    if (
      microphoneAccess.value === "denied" ||
      microphoneAccess.value === "prompt"
    ) {
      requestMicrophonePermission();
      return;
    }
    playing.value = false;
    context.resume();
    if (!initialized.value) {
      mic = new UserMedia();
      recorder = new Recorder();
      mic.connect(recorder);
      mic.open();
      initialized.value = true;
    }
    if (!recording.value) {
      recorder.start();
    } else {
      const data = await recorder.stop();
      file.value = data;
      const blobUrl = URL.createObjectURL(data);
      player = new Player(blobUrl).toDestination();
      player.onstop = () => {
        playerStoped();
      };
    }
    recording.value = !recording.value;
  };
  const togglePlayback = () => {
    if (player && player.loaded) {
      if (player.state === "started") {
        player.stop();
        playerStoped();
      } else {
        player.start();
        playing.value = true;
      }
    }
  };
  const playBeep = () => {
    let beepPlayer = new Player(beep).toDestination();
    beepPlayer.autostart = true
  };

  const stopPlayer = () => {
    if (player && player.loaded) {
      if (player.state === "started") {
        player.stop();
        playerStoped();
      }
    }
  };
  function playerStoped() {
    playing.value = false;
  }
  return {
    microphoneAccess,
    requestMicrophonePermission,
    toggleRecording,
    togglePlayback,
    micSupported,
    recording,
    playing,
    stopPlayer,
    file,
    playBeep,
  };
}
