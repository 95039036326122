import Dashboard from "@/pages/Student/Dashboard.vue";
import MockTestEvaluation from "@/pages/Student/MyPractice/Evaluation/MockTestEvaluation.vue";
import QuestionBasedEvaluation from "@/pages/Student/MyPractice/Evaluation/QuestionBasedEvaluation.vue";
import ExamInstance from "@/pages/Student/MyPractice/Exam/MockTest/ExamInstance.vue";
import QuestionBasedInstance from "@/pages/Student/MyPractice/Exam/QuestionBased/QuestionBasedInstance.vue";
import IndividualQuestionInstance from "@/pages/Student/MyPractice/Individual/ExamInstance.vue";
import Forbidden from '@/pages/Website/Pages/NotFound.vue'
import NoContent from '@/pages/Website/Pages/ContentNotFound.vue'
import Contact from "@/components/Contact/Contact.vue";
import ContactTab from "@/pages/Student/Contact/Contact.vue";
import FAQ from "@/components/Contact/FAQ.vue";
import Terms from "@/components/Contact/Terms.vue";
import Privacy from "@/components/Contact/Privacy.vue";
import Information from "@/pages/Student/Information/Information.vue";
import Single from "@/pages/Student/Information/Single.vue";
import Referal from "@/pages/Student/Referal/Referal.vue";
import Subscription from "@/pages/Student/Packages/Subscription.vue";
import Checkout from "@/pages/Student/Packages/Checkout.vue";
import Profile from "@/pages/Student/Profile/Profile.vue";
import QuestionBasedTest from "@/pages/Student/MyPractice/QuestionBasedTest.vue";
import IndividualQuestionTest from "@/pages/Student/MyPractice/IndividualQuestionTest.vue";
import MockTest from "@/pages/Student/MyPractice/MockTest.vue";

const protected_routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
  },
  {
    path: "/mock-tests",
    name: "mock-tests",
    component: MockTest
  },
  {
    path: "/question-based-tests",
    name: "question-based-tests",
    component: QuestionBasedTest
  },
  {
    path: "/individual-question-test",
    name: "individual-question-test",
    component: IndividualQuestionTest
  },
  {
    path: "/individual-question-test/exam/:id/:topic_id/:index",
    name: "individualQuestion",
    component: IndividualQuestionInstance
  },
  // {
  //   path: "/answer",
  //   name: "initailTest",
  //   component: () => import("@/pages/Student/MyPractice/Exam/InitialTest.vue"),
  //   meta: {
  //     layout: "answerLayout",
  //   },
  // },
  {
    path: "/exam/mocktest/:id",
    name: "mocktest",
    component: ExamInstance,
    meta: {
      layout: "answerLayout",
    },
  },
  {
    path: "/exam/question-based-test/:id",
    name: "questionbasedtest",
    component: QuestionBasedInstance,
    meta: {
      layout: "answerLayout",
    },
  },

  {
    path: '/evaluation/mock-test/:exam',
    name: 'mockTestEvaluation',
    component: MockTestEvaluation
  },
  {
    path: '/evaluation/question-based-test/:test',
    name: 'QBTEvaluation',
    component: QuestionBasedEvaluation
  },
  {
    path: '/referal',
    name: 'referal',
    component: Referal
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: Subscription
  },
  {
    path: '/subscription/checkout/:uuid',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/info',
    name: 'contact',
    component: ContactTab,
    children: [
      {
         path: '/contact',
         name: 'contactTab',
         component: Contact
      },
      {
        path: '/faq',
        name: 'faqTab',
        component: FAQ
      },
      {
        path: '/terms-conditions',
        name: 'termsTab',
        component: Terms
      },
      {
        path: '/privacy-policy',
        name: 'privacyTab',
        component: Privacy
      }
    ]
  },
  {
    path: '/information',
    name: 'information',
    component: Information
  },
  {
    path: '/information/:slug',
    name: 'single_information',
    component: Single,
  },
  {
    path: '/:catchAll(.*)',
    component: Forbidden,
    name: 'Forbidden'
  },
  {
    path: '/no-content',
    component: NoContent,
    name: 'ContentNotFound'
  },
];
protected_routes.forEach((r) => {
  r.meta = {
    ...(r.meta || {}),
    requiresAuth: true,
    user_type: 'student'
  };
});
export default protected_routes;
