import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { useAnswerInstanceStore } from "./answerInstance-store";

export const useExamInstanceStore = defineStore("examInstance", () => {
  const answerInstanceStore = useAnswerInstanceStore();
  const fetching = ref(false)
  const introContent = ref({})
  const questions = ref([])
  const examId = ref(null)
  const examDetail = ref(null)

  async function getModuleIntro() {
    let url = `exam/exam-instance/${examId.value}/get-intro-content`;
    await api.get(url).then(res=>{
      introContent.value = res.data
    }).catch(error =>{})
  }
  async function getExamDetail(id = null) {
    let url = `exam/exam-instance/${id ? id :  examId.value}`;
    await api.get(url).then(res=>{
      examDetail.value = res.data
      return res.data
    }).catch(error =>{})
  }
  async function getQuestionList() {
    let url = `exam/exam-instance/${examId.value}/get-questions`;
    const res = await api.get(url).then(res=>{
      questions.value = res.data
    }).catch(error =>{
    })
    return res;
  }
  async function getExamInstance (id)
  {
    fetching.value = true
    examId.value = id
    try {
      await getExamDetail()
      await getModuleIntro()
      if(!examDetail.value.is_attempted) {
        await start()
      }
      await getQuestionList()
      fetching.value = false
    }catch(err) {
      throw err
    }
  }
  async function start (id = null)
  {
    let url = `exam/exam-instance/${id ? id :  examId.value}/start/`;
    await api.post(url).then(res=>{
      return res;
    }).catch(error =>{})
  }
  async function finish ()
  {
    let url = `exam/exam-instance/${examId.value}/finish/`;
    const res = await api.post(url).then(res=>{
      return res.success;
    }).catch(error =>{})
    return res
  }
  function reset () {
    answerInstanceStore.resetAnswer()
    fetching.value = false
    introContent.value = {}
    questions.value = []
    examId.value = null
    examDetail.value = null
  }
  async function saveCurrentState (state) {
    let url = `exam/exam-instance/${examId.value}/update-user-state/`;
    await api.post(url, {user_state:  state}).then(res=>{
      return res;
    }).catch(error =>{})
  }
  return {fetching, introContent, examId, questions, getExamInstance, examDetail, getExamDetail, start, finish, reset, saveCurrentState}
});
