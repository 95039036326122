function removeTags(htmlString) {
    // Regular expression to match HTML tags
    var regex = /(<([^>]+)>)/ig;
    // Replace HTML tags with an empty string
    return (htmlString || '').replace(regex, "");
}
function mockPromise (status = true) {
    return new Promise((resolve) => resolve(status))
}
function limitString (text, count = 100) {
    text = text || ''
    return text.slice(0, count) + (text.length > count ? "..." : "")
}
function downloadResponse (content, fileName) 
{
        // Create a blob URL for the CSV file
    const url = window.URL.createObjectURL(new Blob([content]));

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`); // Set the filename for the download

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the blob URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    
}
export {
    removeTags,
    mockPromise,
    limitString,
    downloadResponse
}