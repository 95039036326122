<template>
  <v-sheet
    class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
    variant="flat"
  >
    <v-sheet class="kiwi-mock-test-header py-3 px-4" variant="flat">
      <div class="d-flex flex-wrap align-center">
        <img
          src="/assets/mortarboard-02.svg"
          width="36"
          height="36"
          class="mr-2"
        />
        <span class="font-weight-bold kiwi-fs-20" style="flex: 1"
          >Question Based Tests</span
        >
      </div>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet class="pt-3 px-2" variant="flat">
      <v-tabs  hide-slider>
        <v-tab
          v-for="(menu, i) in tabs"
          :key="i"
          :class="activeQTab === menu.id ? ' kiwi-bg-primary qbt-active-tab-menu kiwi-text-white' : ''"
          class="mr-2 kiwi-border-light-grey-4 text-none kiwi-text kiwi-text-light-black-2 qbt-tab-menu"
          style="border: 1px solid transparent; border-radius: 8px 8px 0 0"
          @click.prevent="setActiveTab(menu.id)"
          ><img :src="activeQTab === menu.id ? menu.icon_w : menu.icon" class="mr-2 size-18" /> {{ menu.label }}</v-tab
        >
      </v-tabs>
    </v-sheet>
    <v-divider class="kiwi-border-primary"></v-divider>
    <v-sheet variant="flat">
      <v-sheet
        class="kiwi-bg-light-grey-7 py-2 px-4 d-flex flex-wrap"
        variant="flat"
        style="margin: 0 -8px"
      >
        <v-chip
          label
          class="ma-1 kiwi-fs-14 forn-weight-semi-bold kiwi-qbt-topic border kiwi-radius-4 kiwi-text-light-black-2"
          variant="flat"
          :class="
            list.id === activeTopic
              ? 'kiwi-bg-primary-dim-50 kiwi-text-primary-overwrite kiwi-border-primary font-weight-semi-bold'
              : 'kiwi-bg-white kiwi-bg-black'
          "
          v-for="(list, i) in chapterItems"
          :key="i"
          @click.prevent="activeTopic = list.id"
          size="large"
          style="font-size: 14px;"
        >
          {{ list.title }}
        </v-chip>
      </v-sheet>
      <v-divider></v-divider>
      <div class="text-center pb-10 pt-10 mt-10 mb-10" v-if="fetching.tests">
        <Loading  />
      </div>
      <v-sheet class="kiwi-mock-test-content py-3 px-4" variant="flat" v-else
        >
        <Test
          v-for="(test, i) in questionsBasedTests"
          :key="i"
          class="my-4"
          :test="test"
          :type="'question-based'"
        />
      </v-sheet>
    </v-sheet>
    <v-divider></v-divider>

    <div>
      <v-sheet class="kiwi-bg-light-grey-7 kiwi-text-black py-3 px-4">
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex align-center">
              <v-select
                border
                class="kiwi-bg-white kiwi-border-light-grey-6 mr-2 limit-select"
                :items="[10, 15, 20, 25]"
                v-model="pagination.pageSize"
                flat
                variant="outlined"
                placeholder="1"
                hide-details
                style="max-width: 90px;"
                @update:modelValue="() => { pagination.page = 1; getQuestionBasedTests(activeTopic) }"
              ></v-select>
            <span v-if="questionsBasedTests.length">Showing: {{ showing }} to {{ showing + questionsBasedTests.length - (showing === 1 ?  1 : 0)  }} </span>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <v-pagination
              v-model="pagination.page"
              :length="pageCount"
              :total-visible="pagination.pageSize"
              variant="text"
              @update:modelValue="getQuestionBasedTests(activeTopic)"
              density="compact"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script setup>
import Test from "@/components/Dashboard/QuestionCard.vue";
import { ref, onMounted, computed, watch , nextTick} from "vue";
import { useQustionsBasedTestStore } from "@/stores/questionBased-store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
const route = useRoute()
const questionBasedStore = useQustionsBasedTestStore();
const { getQuestionBasedTests, getAllTopics } = questionBasedStore;
const { questionsBasedTests, pagination, total, topics, fetching } =
storeToRefs(questionBasedStore);
pagination.value.page = 1
const paginationLimiter = ref(10);
const pageCount = computed(() => {
  return Math.ceil(total.value / pagination.value.pageSize);
});
getAllTopics()
const iconMap = {
    speaking: {
        icon: '/assets/voice_black.svg',
        icon_w: '/assets/voice_white.svg',
    },
    writing: {
        icon: '/assets/pencil-edit-01_black.svg',
        icon_w: '/assets/pencil-edit-01_white.svg',
    },
    reading: {
        icon: '/assets/book-open-black.svg',
        icon_w: '/assets/book-open-white.svg',
    },
    listening: {
        icon: '/assets/headphones-black.svg',
        icon_w: '/assets/headphones-white.svg',
    },
}
const tabs = computed(() => {
    return topics.value.map(topic => {
        return {
            ...(iconMap[topic.slug] || {}),
            label: topic.title,
            value: topic.slug,
            id: topic.id,
        }
    })
})
const activeQTab = ref(route.query.m ? parseInt(route.query.m) : null);
const activeTopic = ref(route.query.topic ? parseInt(route.query.topic) : null);
const setActiveTab = (module) => {
    activeQTab.value = module
    activeTopic.value = null
}
const chapterItems = computed(() => {
    const topic =  topics.value.find(t => t.id === activeQTab.value)
    if(!topic) {
        return [];
    }
    return topic.children
})
watch(topics, (newVal, oldVal) => {
    if(!activeQTab.value && newVal.length) {
      activeQTab.value = topics.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeQTab, (newVal, oldVal) => {
    if(activeQTab.value && !activeTopic.value && chapterItems.value.length) {
       activeTopic.value = chapterItems.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeTopic,  (newVal, oldVal) => {
    if(activeTopic.value) {
      pagination.value.page = 1
      getQuestionBasedTests(activeTopic.value)
    }
}, {immediate: true})
watch(chapterItems , (newVal , oldVal) =>{
  if (newVal) {
    const matchingTopic = topics.value.find(topic => {
      return topic.children.find(child => child.id === activeTopic.value);
    });
    if(matchingTopic && !activeQTab.value) {
      activeQTab.value= matchingTopic.id;
    }
  }
})
const showing = computed(() => {
  return pagination.value.page <= 1 ? 1 : (pagination.value.pageSize * (pagination.value.page - 1))
})
</script>

<style lang="scss">

</style>
