import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useDashboardStore = defineStore('scoreTarget' , () =>{
    const streams  = ref([]);
    const dashboardData  = ref(null);
    async function setTargetScore(paylaod){
      const res  = await  api.post('answer/set-target-score/', paylaod) 
      return res;
    }
    async function updateTargetScore(payload){
        const res =  await api.put('answer/target-score/', payload);
        return res
    }
    function getStreams(){
        api.get('user/streams/').then(res=>{
            streams.value = res.data;
        }).catch(error =>{

        })
    }
    async function getDashboardData() {
        try {
            const response = await api.get('home', {
                params: {
                    fields: 'children',
                },
            });
            dashboardData.value = response.data
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    }
    return {streams , getStreams , setTargetScore , updateTargetScore , getDashboardData , dashboardData}
})