import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { ref } from "vue";

export const useMockTestStore = defineStore('mockTest' , () =>{
    const mockTests = ref([])
    const total = ref(0);
    const pagination = reactive({
        pageSize:10,
        page:1,
        // next:null,
        // previous:null,
    })
    const fetching = ref({
       tests: false
    });
    function getMockTests(){
        fetching.value.tests = true
        let url = `exam/exam-instance/?page_size=${pagination.pageSize}&page=${pagination.page}`
        return api.get(url).then(res=>{
            const response  = res.data
            total.value = response.count
            mockTests.value = response.results
            fetching.value.tests = false
            return res;
        }).catch(error =>{
          // fetching.value.tests=false
        })
    }
    return {mockTests, getMockTests,total,pagination,fetching}
})
