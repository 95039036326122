import { useRouter, useRoute } from "vue-router";

export function useTestAction(type = "mock-test") {
  const router = useRouter();
  const route = useRoute();
  const isQbt = type === "question-based";
  const isIQT = type === "individual-question"
  const upgrade = () => {
    return router.push({ name: "subscription", query: {} });
  };
  const start = (id) => {
    // return router.push({name: isQbt ? 'questionbasedtest' : 'mocktest', params: {id: id}})
    const routeName = isQbt ? "questionbasedtest" : "mocktest";
    const url = router.resolve({ name: routeName, params: { id: id } }).href;
    window.open(url, "_blank");
  };
  const startIndividual = (id, topic_id, index) => {
    const routeName = isIQT ? "individualQuestion" : "individual-question-test";
    router.push({
      name: routeName,
      params: {
        id: id,
        topic_id: topic_id,
        index: index
      },
      query: {
        ...route.query
      }
    });
  };
  const viewResult = (id) => {
    if (isQbt) {
      return router.push({ name: "QBTEvaluation", params: { test: id } });
    }
    return router.push({ name: "mockTestEvaluation", params: { exam: id } });
  };
  const mockTestAction = (test) => {
    if (test.is_completed) {
      viewResult(test.id);
    } else if (!test.is_accessible)  {
      upgrade();
    } else {
      start(test.id);
    }
  };
  const IndividualTestAction = (test, index) => {
    if (test.is_completed) {
      viewResult(test.id);
    } else if (!test.is_accessible && test.is_accessible === false)  {
      upgrade();
    } else {
      startIndividual(test?.id, test?.topic?.id, index);
    }
  };
  return {
    mockTestAction,
    IndividualTestAction,
    start,
    startIndividual,
    upgrade
  };
}
