<template>
  <v-sheet
    class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
    variant="flat"
  >
    <v-sheet class="kiwi-mock-test-header py-3 px-4" variant="flat">
      <h1 class="kiwi-fs-20 font-weight-bold">My Mock Tests</h1>
    </v-sheet>
    <v-divider></v-divider>
    <div class="text-center pb-10 pt-10 mt-10 mb-10" v-if="fetching.tests && mockTestList.length ===0">
      <Loading  />
    </div>
    <v-sheet class="kiwi-mock-test-content py-3 px-4">
      <v-row class="mt-0 mb-2" v-if="gridList !== null">
        <v-col sm="6"  md="6" lg="3" xs="12" v-for="(test, i) in gridList" :key="i">
            <TestGrid :type="'mock-test'" :test="test"/>
        </v-col>
      </v-row>
      <Test
        v-for="(test, i) in mockTestList"
        :key="i"
        class="my-4"
        :test="test"
      />
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet class="kiwi-bg-light-grey-7 kiwi-text-black py-3 px-4">
      <v-row>
        <v-col cols="12" md="6">
          <div class="d-flex align-center">
            <v-select
              border
              class="kiwi-bg-white kiwi-border-light-grey-6 mr-2 limit-select"
              :items="[10, 15, 20, 25]"
              v-model="pagination.pageSize"
              flat
              variant="outlined"
              placeholder="1"
              rounded="lg"
              hide-details
              style="max-width: 90px"
              @update:modelValue="() => { pagination.page = 1; getMockTests() }"
            ></v-select>
            <span>Showing: {{ showing }} to  {{ showing + mockTests.length - (showing === 1 ?  1 : 0)  }} </span>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="text-right"
          >
          <v-pagination
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="totalVisible"
            variant="text"
            density="compact"
            @update:modelValue="getMockTests"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script setup>
import Test from "@/components/Dashboard/QuestionCard.vue";
import TestGrid from "@/components/Dashboard/TestGrid.vue";
import { useMockTestStore } from "@/stores/mockTest-store";
import { storeToRefs } from "pinia";
import { onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
const totalVisible = ref(10);
const mockTestStore = useMockTestStore();
const { getMockTests } = mockTestStore;
const { mockTests, pagination, total , fetching} = storeToRefs(mockTestStore);
pagination.value.page = 1
const pageCount = computed(() => {
  return Math.ceil(total.value / pagination.value.pageSize);
});
const gridList = ref([])
onMounted(() => {
  getMockTests().then(res=> {
    gridList.value = mockTests.value.splice(0, 4);
  });
});

const mockTestList = computed(() => {
    const gridListIds = gridList.value.map(t => t.id)
    return mockTests.value.filter(test => !gridListIds.includes(test.id))
})
const showing = computed(() => {
  return pagination.value.page <= 1 ? 1 : (pagination.value.pageSize * (pagination.value.page - 1))
})
</script>

<style></style>
