import { api } from "@/plugins/axios";
import axios from 'axios';
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePackageStore = defineStore('packageList' , () =>{
    const packages = ref([])
    const transactionData = ref([])
    const tokens = ref({cancel: null})
    
    function getPackageList(){
        let url = `subscriptions/packages/`
        api.get(url).then(res=>{
            const response  = res.data
            packages.value = response
        })
    }
    
    async function createTransaction(packageId){
        try{
            let url = 'subscriptions/transaction/'
            const res = await api.post(url, {package : packageId})
            return res.data
        }catch(error){
            // console.log('Error: ', error)
            throw error
        }
    }
    
    function getTransaction(uuid){
        let url = `subscriptions/transaction/${uuid}/`
        api.get(url).then(res => {
            transactionData.value = res.data
        })
    }

    async function redeemPromocode(transaction_id, code){
        try{
            let url = 'subscriptions/redeem-promo/'
            const res = await api.post(url, {transaction_id: transaction_id, promo_code: code})
            return res
        }catch(error){
            if(error?.response?.status == 400){
                throw {
                    type: 'validation', 
                    message: error.response?.data?.data?.error
                } 
            }else{
                // console.log('Error Message: ', error)
            }
        }
    }

    async function redeemVoucher(code){
        try{
            let url = 'subscriptions/subscribe-package/'
            const res = await api.post(url, {voucher_code: code})
            return res
        }catch(error){
            if(error?.response?.status == 400){
                throw {
                    type: 'validation', 
                    message: error.response?.data?.data?.error || error.response?.data?.data?.message
                } 
            }else{
                // console.log('Error Message: ', error)
            }
        }
    }

    async function checkVoucher(code){
        if (tokens.value.cancel) {
            tokens.value.cancel.cancel()
        }
        try{
            const url = `subscriptions/get-voucher-detail/?voucher_code=${code}`
            tokens.value.cancel = axios.CancelToken.source()
            const res = await api.get(url, {
                cancelToken: tokens.value.cancel.token
            })
            return res
        }catch(error){
            if(error?.response?.status == 400){
                throw {
                    type: 'validation', 
                    message: error.response?.data?.data?.message
                } 
            }else{
                // console.log('Error Message: ', error)
            }
        }
    }

    return {packages, getPackageList, createTransaction, transactionData, getTransaction, redeemPromocode, redeemVoucher, checkVoucher}
})