<template>
<v-sheet
    class="kiwi-bg-light-grey-6 position-relative overflow-hidden kiwi-text-black h-100 kiwi-radius-8 kiwi-border-light-grey-3 border"
    >
    <img
        src="/assets/eclipse-mask2.png"
        class="position-absolute z-1 top-0"
        alt=""
        style="object-fit: contain; right: 0px"
    />
    <div class="content position-relative z-2 pa-4">
        <div class="title kiwi-fs-18 font-weight-bold">
        {{ test.description }}
        </div>
        <div class="d-flex align-center flex-wrap">
        <div
            class="author-wrapper kiwi-fs-14 d-flex flex-wrap has-dot-seprator"
        >
            <span>{{ test.subjects.length }} Modules</span>
            <v-icon class="mdi mdi-circle-small px-2"></v-icon>
            <span> {{ test.total_number_of_question }} Questions</span>
        </div>
        <span class="pa-1 d-block">-</span>
        <div class="d-flex duratio align-center kiwi-fs-14">
            <img src="/public/assets/timer-02.svg" class="mr-1" alt="" style="width: 16px; height: 16px;" />
            <span>{{test.total_time_duration ? test.total_time_duration / 60  : 0}}hrs</span>
        </div>
        </div>
    </div>
    <v-divider></v-divider>
    <v-sheet
        class="kiwi-bg-white position-relative overflow-hidden kiwi-text-black h-100"
    >
        <div class="content position-relative z-2 pa-4">
        <div class="d-flex flex-wrap">
            <img
            src="/public/assets/score-icon.svg"
            class="mr-3"
            alt=""
            />
            <div>
            <span class="d-block font-weight-semi-bold kiwi-fs-16"
                >Overall Score</span
            >
            <span
                class="kiwi-text-orange font-weight-semi-bold kiwi-fs-16"
                >{{test.exam_attempt_data?.is_completed ? parseFloat(test.exam_attempt_data?.score) : '--'}}/{{test.total_marks || 90}}</span
            >
            </div>
        </div>
        <div class="d-flex flex-wrap mt-4">
            <v-progress-circular
            :model-value="test.attempted_question_percentage"
            :size="42"
            :width="2"
            class="mr-3"
            >
            <template v-slot:default>
                <div
                class="inner-status d-flex flex-column flex-wrap align-center justify-center"
                >
                <span class="kiwi-fs-12 font-weight-bold"
                    >{{ test.attempted_question_percentage }}%</span
                >
                </div>
            </template>
            </v-progress-circular>
            <div>
            <span
                class="kiwi-fs-16 d-block font-weight-semi-bold"
                style="line-height: 1"
                >Status</span
            >
            <span
                class="kiwi-fs-12 font-weight-medium kiwi-text-light-black-2"
                style="line-height: 1"
                >
                <template v-if="test.is_completed">Completed</template>
                <template v-else-if="test.is_attempted">In Progress</template>
                <template v-else>Not Started</template>
                </span
            >
            </div>
        </div>
        <v-divider class="my-4"></v-divider>

        <v-btn
                v-if="test.is_completed"
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-primary kiwi-border-primary text-center mr-3"
                rounded="xl"
                variant="outlined"
                @click="mockTestAction(test)"
                >View Result
        </v-btn>
        <v-btn
            block
            class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-white kiwi-bg-warning text-center mr-3"
            rounded="xl"
            variant="flat"
            v-else-if="!test.is_accessible"
            @click="mockTestAction(test)"
            ><v-icon class="la la-crown mr-2"></v-icon>
            <span>Upgrade Plan</span></v-btn>
        <v-btn
                v-else-if="test.is_attempted"
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-primary kiwi-border-primary text-center mr-3"
                rounded="xl"
                variant="outlined"
                @click="resume(test)"
                >Resume
        </v-btn>
        <v-btn
                v-else
                block
                class="kiwi-button text-capitalize font-weight-semi-bold kiwi-text-white kiwi-bg-primary text-center mr-3"
                rounded="xl"
                variant="flat"
                @click="mockTestAction(test)"
                >Start
        </v-btn>
        </div>
    </v-sheet>
    </v-sheet>
    <PromptDialog v-if="prompt" v-bind="prompt" />
</template>
<script setup>
import { ref } from "vue";
import { useTestAction } from "@/composables/useTestAction";
import PromptDialog from "../Prompt/PromptDialog.vue";
const props = defineProps({
test: Object,
  type: {
    type: String,
    default: "mock-test",
  },
});
const {mockTestAction} = useTestAction(props.type)
const prompt = ref(null)
const resume = (test) => {
  prompt.value = {
    title: 'Confirm?',
    content: 'You did not finish this test last time. Do you want to continue from your saved session?',
    actions: [
      {
        text: "Close",
        onClick: () => prompt.value = null,
      },
      {
        text: "Continue",
        type: "primary",
        onClick: () => {
            prompt.value = null
            mockTestAction(test)
        }
      },
    ],
  }

}
</script>
