<template>
  <Card :handleControl="scoreControl" class="mt-4 mt-md-4 ">
    <template v-slot:mediaBg>
      <img
        src="/assets/blobs-group.svg"
        class="position-absolute"
        style="height: 250px; top: -70px; right: -36px"
      />
    </template>
    <article class="kiwi-max-w-100">
      <img src="/assets/score_target-icon.svg" alt="" />
      <div
        class="title mt-2 kiwi-fs-16 font-weight-bold kiwi-lh-1-md text-uppercase"
      >
        {{ scoreContent.title }}
      </div>
      <div class="d-flex justify-space-between align-center w-100 mt-3">
        <div class="score kiwi-fs-48 font-weight-bold" style="line-height: 1">
          {{ targetScore }}
        </div>
        <v-btn
          variant="flat"
          size="small"
          @click="editScore"
          class="kiwi-radius-4 kiwi-text-blue text-none kiwi-bg-white kiwi-fs-14 font-weight-semi-bold shadow-btn-1"
          style="padding: 6px 10px 6px 10px"
          ><img src="/public/assets/score_pencil-icon.svg" class="mr-1" />{{
            scoreContent.action
          }}</v-btn
        >
      </div>
    </article>
  </Card>
  <ScoreModal @update:dialog="updateDialog" :dialog="dialog" v-if="dialog" :is-update="true" :target-score="targetScore"/>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDashboardStore } from "@/stores/dashboard-store";
import ScoreModal from "@/components/Dashboard/ScoreModal.vue";
const dashboardStore = useDashboardStore();
const { dashboardData } = storeToRefs(dashboardStore);
const targetScore = computed(() => {
  return dashboardData.value && dashboardData.value.target_score !== undefined
    ? dashboardData.value.target_score
    : "";
});
const dialog = ref(false);

const editScore = () =>{
  dialog.value = true; 
}
const scoreControl = ref({
  bg: "blue-gradient-1",
  textColor: "white",
  mode: "media-bg",
});

const scoreContent = ref({
  title: "YOUR TARGET SCORE",
  score: "70",
  action: "Edit Score",
});

const updateDialog = (newDialogValue) => {
    dialog.value = newDialogValue;
};

const modalOpen = ref(false);
</script>

<style></style>
